import Popover from 'bootstrap/js/dist/popover';
import { primaryInput } from 'detect-it';

interface PopoverOptions {
  followMouse?: boolean;
  manual?: boolean;
}

const defaultOptions = <PopoverOptions>{
  followMouse: false,
  manual: false,
};

const generateGetBoundingClientRect = (x = 0, y = 0) => {
  return () =>
    <DOMRect>{
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    };
};

const initPopover = (element: HTMLElement, content?: string | HTMLElement, options?: PopoverOptions) => {
  if (primaryInput === 'touch') {
    // we don't want a popover on primary touch devices
    return;
  }

  options = Object.assign({}, defaultOptions, options);

  const popoverOptions = <any>{
    delay: {
      show: 300,
      hide: 0,
    },
  };

  if (content) {
    popoverOptions.content = content;
  }

  const popover = new Popover(element, popoverOptions);

  if (options.followMouse) {
    element.addEventListener('mousemove', ({ clientX: x, clientY: y }: MouseEvent) => {
      element.getBoundingClientRect = generateGetBoundingClientRect(x, y);
      popover.update();
    });
  }

  if (options.manual) {
    element.addEventListener('mouseenter', () => {
      popover.show();
    });

    element.addEventListener('mouseleave', () => {
      popover.hide();
    });
  }
};

export { initPopover, PopoverOptions };
