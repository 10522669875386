import { initPopover, PopoverOptions } from './popover';
import './styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll<HTMLElement>('[data-bs-toggle="popover"]').forEach((element) => {
    const options = <PopoverOptions>{
      followMouse: element.dataset.anwFollowMouse || false,
      manual: (element.dataset.bsTrigger && element.dataset.bsTrigger === 'manual') || false,
    };

    initPopover(element, undefined, options);
  });
});
